import { Component, OnInit, ChangeDetectorRef } from "@angular/core";
import { Form, FormGroup, FormControl } from "@angular/forms";
import { Router } from "@angular/router";
import { MatDialog, MatDialogRef } from "@angular/material/dialog";
import { MatIconModule } from "@angular/material/icon";
import { ServService } from "../../../app/serv.service";
import { Family } from "../../../Models/Family";
import { ChildrenRoport } from "../../../Models/ChildrenReports";
import * as moment from "moment";
import "ag-grid-enterprise";
import { LOCALE_TEXT } from "../../../app/Additions/constants";
import { UpdateChildrenComponent } from "../../update-children/update-children.component";
import { ReportsDet } from "../../../Models/ReportsDet";
import { AppProxy } from "../../../app/app.proxy";
import { pdfByClass, levelInBranch } from "../children-list/children-list.component";


@Component({
  selector: "app-debit-report",
  templateUrl: "./debit-report.component.html",
  styleUrls: ["./debit-report.component.css"]
})
export class DebitReportComponent implements OnInit {
  dialogRef: any;
  backgroundColor: string;
  private pivotMode;
  private column;
  private rowData;
  private gridOptions;
  private gridColumnApi: any;
  private gridApi: any;
  private columnTypes;
  private visible: boolean;
  private childrenReport: Array<ChildrenRoport>;
  private sideBar ;
  numRows=0;
  sumTotal=0;
  sumPade=0;
  currentiFamilyIdTemp;
  ReportsDet: ReportsDet;
  lReportsDet: Array<ReportsDet> = new Array<ReportsDet>();
  dFromDate: Date = undefined;
  ngOnDestroy() {
    this.serv.currentiFamilyId = this.currentiFamilyIdTemp;
  }

  constructor(
    public serv: ServService,
    private route: Router,
    private updateChildDailog: MatDialog
  ) {
    this.backgroundColor = this.serv.backgroundColor[3];

    this.columnTypes = {
      dateColumn: {
        filter: "agDateColumnFilter",
        filterParams: {
          clearButton: true,
          inRangeInclusive:true,
          comparator: function (filterLocalDateAtMidnight, cellValue) {
            if (cellValue != null) {
              let dateParts = cellValue.split("/");
              let cellDate = new Date(
                Number(dateParts[1]) +
                "/" +
                Number(dateParts[0]) +
                "/" +
                Number(dateParts[2])
              );
              if (cellDate < filterLocalDateAtMidnight) {
                return -1;
              } else if (cellDate > filterLocalDateAtMidnight) {
                return 1;
              } else {
                return 0;
              }
            } else {
              return 0;
            }
          }
        }
      }
    };
  }

  ngOnInit() {
    this.serv.visible = true;
    this.dFromDate = new Date(
      JSON.parse(localStorage.getItem("dDebitFamilyFromDate"))
    );
    this.gridOptions = {
      localeText: LOCALE_TEXT,
      context: { componentParent: this },
      enableRtl: true,
      enableCharts: true,
      onGridReady: this.onGridReady,
      defaultColDef: {
        resizable: true,
        filter: true,
        enableRowGroup: true,
        enablePivot: true,
        enableValue: true
      },
      sideBar:true,
      // sideBar: {
      //   toolPanels: [
      //     {
      //         id: 'columns',
      //         labelDefault: 'Columns',
      //         labelKey: 'columns',
      //         iconKey: 'columns',
      //         toolPanel: 'agColumnsToolPanel',
      //     },
      //     {
      //         id: 'filters',
      //         labelDefault: 'Filters',
      //         labelKey: 'filters',
      //         iconKey: 'filter',
      //         toolPanel: 'agFiltersToolPanel',
      //     }
      //   ],
      //   position: 'left',
      //   defaultToolPanel: 'filters'
      // }
    };
    this.pivotMode=true;

    this.column = [
      {
        headerName: "פתיחה",
        field: "iFamilyId",
        width: 90,
        suppressFilter: true,
        cellRenderer: function (params) {
          var eDiv = document.createElement("div");
          eDiv.innerHTML =
            '<button mat-icon-button type="submit" class="btn-icon-grid" id="edit"><i class="icon-locked"></i></button>';
          var eButton = eDiv.querySelectorAll("#edit")[0];
          eButton.addEventListener("click", function () {
            params.context.componentParent.editFamily(params.data);
          });

          return eDiv;
        },
        pinned:'right'
      },
      {
        headerName: "משפחה",
        field: "nvFamilyName",
        // width: 160,
        filter: "agTextColumnFilter",
        sortable: true,
        enableRowGroup: true,
        enablePivot: true
      },
      {
        headerName: "טלפון",
        field: "nvPhone",
        // width: 160,
        filter: "agTextColumnFilter",
        sortable: true
      },
      {
        headerName: "טלפון אב",
        field: "nvPhoneF",
        // width: 160,
        filter: "agTextColumnFilter",
        sortable: true
      },
      {
        headerName: "טלפון אם",
        field: "nvPhoneM",
        // width: 160,
        filter: "agTextColumnFilter",
        sortable: true
      },
      {
        headerName: "ת.חיוב",
        field: "dDebitDate",
        type: "dateColumn",
        // width: 155,
        comparator: this.dateComparator,
        sortable: true,
        enableRowGroup: true,
        enablePivot: true
      },

      {
        headerName: "שם",
        field: "nvFirstName",
        // width: 150,
        filter: "agTextColumnFilter",
        sortable: true,
        enableRowGroup: true,
        enablePivot: true
      },
      {
        headerName: "כיתה",
        field: "nvLevel",
        // width: 120,
        filter: "agTextColumnFilter",
        sortable: true,
        enableRowGroup: true,
        enablePivot: true,
        //filter: 'agSetColumnFilter',
        filterParams: { values: "nvLevel" },
      },
      {
        headerName: "סניף",
        field: "nvBranchName",
        // width: 120,
        filter: "agTextColumnFilter",
        sortable: true,
        enableRowGroup: true,
        enablePivot: true
      },
      {
        headerName: "סוג חיוב",
        field: "nvDebitType",
        // width: 120,
        filter: "agTextColumnFilter",
        sortable: true
      },
      {
        headerName: "סוג תעריף",
        field: "nvRateType",
        // width: 120,
        filter: "agTextColumnFilter",
        sortable: true,
        enableRowGroup: true,
        enablePivot: true
      },
      {
        headerName: "שם תעריף",
        field: "nvRateName",
        // width: 120,
        filter: "agTextColumnFilter",
        sortable: true,
        enableRowGroup: true,
        enablePivot: true
      },
      {
        headerName: "יום חיוב",
        field: "iDebitDay",
        // width: 120,
        filter: "agTextColumnFilter",
        sortable: true,
        enableRowGroup: true,
        enablePivot: true
      },
      {
        headerName: "סכום",
        field: "nAmount",
        // width: 120,
        filter: "agTextColumnFilter",
        sortable: true,
        enableValue: true
      },
      {
        headerName: "הנחה",
        field: "nDiscountAmount",
        // width: 130,
        filter: "agTextColumnFilter",
        sortable: true,
        enableValue: true
      },
      {
        headerName: "סכום לאחר הנחה",
        field: "nAmountAfterDiscount",
        // width: 150,
        filter: "agTextColumnFilter",
        sortable: true,
        enableValue: true
      },
      {
        headerName: "ת.ביצוע חיוב",
        field: "dDebitDate",
        // width: 150,
        type: "dateColumn",
        comparator: this.dateComparator,
        sortable: true,
        enableRowGroup: true,
        enablePivot: true
      },
      {
        headerName: "חודש חיוב",
        field: "nvDebitMonth",
        width: 120,
        filter: "agTextColumnFilter",
        sortable: true,
        enableRowGroup: true,
        enablePivot: true
      },
      {
        headerName: "סטטוס",
        field: "nvStatus",
        width: 120,
        filter: "agTextColumnFilter",
        sortable: true
      },
      {
        headerName: "הערות",
        field: "nvComment",
        // width: 150,
        filter: "agTextColumnFilter",
        sortable: true
      },
      {
        headerName: "שולם?",
        field: "nvPaymentStatus",
        width: 120,
        filter: "agTextColumnFilter",
        sortable: true
      },
      {
        headerName: "תשלום יחסית לחיוב",
        field: "nAmountPaid",
        filter: "agTextColumnFilter",
        width: 155,
        sortable: true,
        enableValue: true
      },
      {
        headerName: "הו''ק",
        field: "isDD",
        width: 90,
        filter: "agNumberColumnFilter",
        sortable: true
      },
      {
        headerName: "אשראי",
        field: "isCC",
        width: 90,
        filter: "agNumberColumnFilter",
        sortable: true
      },
      {
        headerName: "עדכון אחרון",
        field: "dLastUpdet",
        type: "dateColumn",
        width: 130,
        comparator: this.dateComparator,
        sortable: true
      }
    ];
    this.getReport();
  }
  dateComparator(date1, date2) {
    if (date1 === null && date2 === null) {
      return 0;
    }
    if (date1 === null) {
      return -1;
    }
    if (date2 === null) {
      return 1;
    }
    let dateParts1 = date1.split("/");
    let cellDate1 = new Date(
      Number(dateParts1[1]) +
      "/" +
      Number(dateParts1[0]) +
      "/" +
      Number(dateParts1[2])
    );
    let dateParts2 = date2.split("/");
    let cellDate2 = new Date(
      Number(dateParts2[1]) +
      "/" +
      Number(dateParts2[0]) +
      "/" +
      Number(dateParts2[2])
    );
    return cellDate1.getTime() - cellDate2.getTime();
  }
  onGridReady(params) {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
    this.gridApi.closeToolPanel();
    //  params.api.sizeColumnsToFit();
  }
  filterChanged(params) {
    let t = this.gridOptions.api.getFilterModel();

/*     this.gridOptions.api.forEachNode(function (node: any) {
      node.setDataValue("indexRow", node.rowIndex + 1);
    }); */
    let filteredRows=this.gridOptions.api.getModel().rootNode.childrenAfterFilter;
    this.numRows=0;
    this.sumTotal=0;
    this.sumPade=0;
     filteredRows.forEach(t=>{
       this.sumTotal+=t.data.nAmountAfterDiscount;
       this.sumPade+=t.data.nAmountPaid;
       this.numRows++;
     })
  }
  sortChanged() {
    this.gridOptions.api.forEachNode(function (node: any) {
      node.setDataValue("indexRow", node.rowIndex + 1);
    });
  }
  getReport() {
    // alert("children")
    this.serv.GetDebitReport(this.dFromDate).then(res => {
      this.numRows=0;
      this.sumTotal=0;
      this.sumPade=0;
      for (let i = 0; i < (res as any[]).length; i++) {
        if (res[i].dDebitDate != null) {
          res[i].dDebitDate = moment(res[i].dDebitDate).format("DD/MM/YYYY");
        } else {
          res[i].dDebitDate = null;
        }
        if (res[i].dCreditDate != null) {
          res[i].dCreditDate = moment(res[i].dCreditDate).format("DD/MM/YYYY");
        } else {
          res[i].dCreditDate = "";
        }
        if (res[i].dLastUpdet != null) {
          res[i].dLastUpdet = moment(res[i].dLastUpdet).format("DD/MM/YYYY");
        } else {
          res[i].dLastUpdet = "";
        }
        this.sumTotal+=res[i].nAmountAfterDiscount;
        this.sumPade+=res[i].nAmountPaid;
        this.numRows++;     
      }

      this.rowData = res;
    });
  }
  
  editFamily(Family) {
    this.serv.currentiFamilyId = Family.iFamilyId;
    this.serv.currentFamily = Family;
    this.route.navigateByUrl("/header/families/tabs/parentDetails")
    this.serv.visible = false;
  }

  clearFiltering() {
    this.gridOptions.api.setRowData(this.rowData);
    let nodes = this.gridApi.getRenderedNodes();
    nodes.forEach(n => n.setSelected(false));
    let filteredRows=this.gridOptions.api.getModel().rootNode.childrenAfterFilter;
    this.numRows=0;
    this.sumTotal=0;
    filteredRows.forEach(t=>{
      this.sumTotal+=t.data.nAmount;
      this.numRows++;
    })
  }

  updateDetails(params) {
    this.dialogRef = this.updateChildDailog.open(UpdateChildrenComponent, {
      hasBackdrop: true,
      height: "800px",
      width: "1400px",

      data: { iChildId: params.iChildId }
    });
  }
  editChild() {
    // this.serv.currentiFamilyId = Family.iFamilyId
    // this.serv.currentFamily = Family;
    // this.router.navigateByUrl('/header/families/tabs/parentDetails');
    // this.serv.visible = false
  }
  onBtExport() {
    // var params = {
    //   skipHeader: false,
    //   allColumns: false,
    //   fileName: "פרוט תשלומים",
    //   sheetName: "xls"
    // };

    // if (getBooleanValue('#skipGroupR')) {
    //     params.shouldRowBeSkipped = function(params) {
    //         return params.node.data.country.charAt(0) === 'R'
    //     };
    // }
    // this.gridOptions.api.exportDataAsExcel(params);

    // this.gridOptions.api.exportDataAsCsv(params);
    var params = {
      skipHeader: false,
      allColumns: false,
      fileName: "פרוט תשלומים"
    };
    this.gridApi.exportDataAsExcel(params);
  }
  // fitColumn() {
  //   console.log("fitColumn")
  //   if (this.gridApi)
  //     this.gridApi.sizeColumnsToFit();
  // }
  printToPDF() {
    // this.lData=new ;
    if (this.gridColumnApi) {
      let lstColumn = this.gridColumnApi.getAllGridColumns();
      this.lReportsDet = new Array<ReportsDet>();
      if (lstColumn)
        var string =
          '<DataTable xmlns="http://schemas.datacontract.org/2004/07/System.Data"><xs:schema id="NewDataSet" xmlns:xs="http://www.w3.org/2001/XMLSchema" xmlns="" xmlns:msdata="urn:schemas-microsoft-com:xml-msdata"><xs:element name="NewDataSet" msdata:IsDataSet="true" msdata:MainDataTable="dt" msdata:UseCurrentLocale="true"><xs:complexType><xs:choice minOccurs="0" maxOccurs="unbounded"><xs:element name="dt"><xs:complexType><xs:sequence>';

      lstColumn.forEach((col, index) => {
        this.ReportsDet = new ReportsDet();
        if (col.visible == true) {
          this.ReportsDet.nFieldWidth = col.actualWidth;
          this.ReportsDet.nvFieldName = col.colId;
          this.ReportsDet.nvFieldTitle = col.colDef.headerName;
          // this.ReportsDet.iFieldOrderby = 0;
          // this.ReportsDet.iFieldSequnce = 0;
          this.lReportsDet.push(this.ReportsDet);
        }
      });
      this.lReportsDet.forEach((col, colIndex) => {
        string +=
          '<xs:element name="a' +
          colIndex +
          '" type="xs:string" minOccurs="0"/>';
      });
      string +=
        '</xs:sequence></xs:complexType></xs:element></xs:choice></xs:complexType></xs:element></xs:schema><diffgr:diffgram xmlns:diffgr="urn:schemas-microsoft-com:xml-diffgram-v1" xmlns:msdata="urn:schemas-microsoft-com:xml-msdata"><DocumentElement xmlns="">';
      this.gridOptions.gridApi.rowModel.rowsToDisplay.forEach(
        (element, dataIndex) => {
          string +=
            '<dt diffgr:id="dt' +
            dataIndex +
            '" msdata:rowOrder="' +
            dataIndex +
            '" diffgr:hasChanges="inserted">';

          this.lReportsDet.forEach((col, colIndex) => {
            if (col.nvFieldName == "indexRow")
              string +=
                "<a" + colIndex + ">" + dataIndex + "</a" + colIndex + ">";
            else
              string +=
                "<a" +
                colIndex +
                ">" +
                element.data[col.nvFieldName] +
                "</a" +
                colIndex +
                ">";
          });
          string += "</dt>";
        }
      );
      string += "</DocumentElement></diffgr:diffgram></DataTable>";
      this.serv.PrintToPDF(this.lReportsDet, string, "דוח חיובים", null).then(res => {
        console.log(res);
        var link = document.createElement("a");
        link.download = res;
        link.href = AppProxy.baseUrl + "Files/" + res;
        window.open(link.href);
      });
    }
  }

  lReportsDetByClass: Array<ReportsDet> = new Array<ReportsDet>();
  ReportsDetByClass: ReportsDet;
  lpdfByClass: Array<pdfByClass>;
  pPdfByClass: pdfByClass;
  //lnvLevel: Array<string> = new Array<string>();
  lLevelInBranch: Array<levelInBranch> = new Array<levelInBranch>();
  lLevel: Array<string> = new Array<string>();
  
  printToPDFByClass() {
    this.lLevelInBranch = new Array<levelInBranch>();
    this.lLevel = new Array<string>();
    this.lpdfByClass = new Array<pdfByClass>();
    let lstColumn;
    this.gridOptions.gridApi.rowModel.rowsToDisplay.forEach(element => {
      
      if (!this.lLevel.includes(element.data.nvLevel)){
        let level:levelInBranch=new levelInBranch()
        level.nvBranch=element.data.nvBranchName;
        level.nvLevel=element.data.nvLevel;
         this.lLevelInBranch.push(level);
        this.lLevel.push(element.data.nvLevel);
      }
    });

    if (this.gridColumnApi) {
      lstColumn = this.gridColumnApi.getAllGridColumns();
      this.lReportsDetByClass = new Array<ReportsDet>();
      lstColumn.forEach((col, index) => {
        this.ReportsDetByClass = new ReportsDet();
        if (col.visible == true) {
          this.ReportsDetByClass.nFieldWidth = col.actualWidth;
          this.ReportsDetByClass.nvFieldName = col.colId;
          this.ReportsDetByClass.nvFieldTitle = col.colDef.headerName;
          this.lReportsDetByClass.push(this.ReportsDetByClass);
        }
      });
    }

    this.lLevelInBranch.forEach(elementLevel => {
      var tempRowData:any[]=[]
      this.pPdfByClass = new pdfByClass();
      this.pPdfByClass.title = 
      "<h1 style='text-align:center; font-weight: bold; font-size: 25px; '>   דוח פרוט חיובים סניף  " + elementLevel.nvBranch+"  כיתה "+elementLevel.nvLevel;

      if (this.gridColumnApi) {
        if (this.lReportsDetByClass)
        this.gridOptions.gridApi.rowModel.rowsToDisplay.forEach(element => {
          if(element.data.nvLevel==elementLevel.nvLevel)
          tempRowData.push(element);
        });
          var string = this.createDataTable(this.lReportsDetByClass, tempRowData);
        this.pPdfByClass.strTable = string;
        this.lpdfByClass.push(this.pPdfByClass);

      }

    });
    this.serv.PrintToPDFByClass(this.lReportsDetByClass, this.lpdfByClass).then(res => {
      console.log(res);
      var link = document.createElement("a");
      link.download = res;
      link.href = AppProxy.baseUrl + "Files/" + res;
      window.open(link.href);
    });
  }

  createDataTable(lReportsDetByClass, data) {
    var string =
      '<DataTable xmlns="http://schemas.datacontract.org/2004/07/System.Data"><xs:schema id="NewDataSet" xmlns:xs="http://www.w3.org/2001/XMLSchema" xmlns="" xmlns:msdata="urn:schemas-microsoft-com:xml-msdata"><xs:element name="NewDataSet" msdata:IsDataSet="true" msdata:MainDataTable="dt" msdata:UseCurrentLocale="true"><xs:complexType><xs:choice minOccurs="0" maxOccurs="unbounded"><xs:element name="dt"><xs:complexType><xs:sequence>';

    lReportsDetByClass.forEach((col, colIndex) => {
      string +=
        '<xs:element name="a' +
        colIndex +
        '" type="xs:string" minOccurs="0"/>';
    });

    string +=
      '</xs:sequence></xs:complexType></xs:element></xs:choice></xs:complexType></xs:element></xs:schema><diffgr:diffgram xmlns:diffgr="urn:schemas-microsoft-com:xml-diffgram-v1" xmlns:msdata="urn:schemas-microsoft-com:xml-msdata"><DocumentElement xmlns="">';
    if (data)
      data.forEach(
        (element, dataIndex) => {
          string +=
            '<dt diffgr:id="dt' +
            dataIndex +
            '" msdata:rowOrder="' +
            dataIndex +
            '" diffgr:hasChanges="inserted">';

          lReportsDetByClass.forEach((col, colIndex) => {
            let dataIndexPlusOne = dataIndex + 1;
            if (col.nvFieldName == "indexRow")
              string +=
                "<a" + colIndex + ">" + dataIndexPlusOne + "</a" + colIndex + ">";
            else
              string +=
                "<a" +
                colIndex +
                ">" +
                element.data[col.nvFieldName] +
                "</a" +
                colIndex +
                ">";
          });
          string += "</dt>";
        }
      );
    string += "</DocumentElement></diffgr:diffgram></DataTable>";
    return string;
  }
  
}
