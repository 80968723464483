import { Component, OnInit } from '@angular/core';
// import "ag-grid-enterprise";
// import { GridApi } from "ag-grid/dist/lib/gridApi";
import { LOCALE_TEXT } from '../../app/Additions/constants';
import { ServService } from '../../app/serv.service';
// import { CellEditorFactory } from 'ag-grid';
import * as moment from "moment"
import { CodeTable } from '../../Models/CodeTable';
import { dailog } from '../../app/Additions/dailog/dailog';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import "ag-grid-enterprise";
import { Router, ActivatedRoute } from '@angular/router';
import { DebitDetailsToMonthComponent } from '../debit-details-to-month/debit-details-to-month.component';
import { DebitDetailsComponent } from '../debit-details/debit-details.component';
import { DebitAdditionByClass } from '../../Models/DebitAdditionByClass';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { CodeTableByBranch } from '../../Models/CodeTableByBranch';
import { Dialog } from 'primeng/dialog';


@Component({
  selector: 'app-debit',
  templateUrl: './debit.component.html',
  styleUrls: ['./debit.component.css']
})
export class DebitComponent implements OnInit {


  bIsEnableDebit: any;
  lClasses: Array<CodeTableByBranch>;
  form: FormGroup;
  nvMonth: string;
  isVisible: boolean = false
  bIsFirstTime: boolean = true;
  sIsFirstTime: string;

  isVisiblePopup = false
  detailsArr: Array<any>
  debitday: CodeTable;
  iDebitDay: number
  montes: Array<{ dtMonth: Date, nvMonth: string }>;
  dDebitMonth: Date = null;
  backgroundColor: string;
  private columnDefs;
  private rowData;
  private gridOptions;
  private gridColumnApi: any;
  private gridApi: any;
  private columnTypes;

  public rowDataStudents=[];
  public gridOptionsStudents;
  public gridColumnApiStudents: any;
  public gridApiStudents: any;
  public columnTypesStudents;
  public columnDefsStudents;

  public static debitFilterState=null;
  public static debitSortState=null;
  public static debitGroupState=[];
  public static debitColState=null;


  numRows=0;
  sumTotal=0;
  debitAdditionByClass1:DebitAdditionByClass=new DebitAdditionByClass()
  fileNameDialogRef: MatDialogRef<dailog>;
  dialogRef: MatDialogRef<DebitDetailsComponent>;
  private dailog: MatDialog
  disabled
  monthsReturn: any;
  constructor(public service: ServService, private mydialog: MatDialog, private router: Router, private activatedRoute: ActivatedRoute, private debitDetaildDailog: MatDialog) {
    this.backgroundColor = this.service.backgroundColor[1];
    this.detailsArr = new Array<any>();
    this.lClasses=new  Array<CodeTableByBranch>();
    this.columnDefsStudents = [
       {
        headerName: "בחר",
        field: "checkbox",
        width: 300,
        checkboxSelection: true,
        suppressFilter: true,
        menuTabs: [],
        headerCheckboxSelection: true,
        pinned:'right'
      },
      {
        headerName: "שם",
        field: "nvChildName",
        width: 500,
        filter: "agTextColumnFilter",
        sortable: true
      },
      {
        headerName: "כיתה",
        field: "nvLevel",
        width: 500,
        filter: "agTextColumnFilter",
        sortable: true
      },
      {
        headerName: "עמותה",
        field: "nvAssociationName",
        width: 500,
        filter: "agTextColumnFilter",
        sortable: true
      }]
      this.gridOptionsStudents = {
        localeText: LOCALE_TEXT,
        context: { componentParent: this },
        enableRtl: true,
        onGridReady: this.onGridReadyStudents,


      };
  }

  ngOnInit() {
    this.GetMonthesCodeTable();
    if (this.service.bfromDebit == true) {
      this.dDebitMonth = this.service.dDebitMonth
      this.iDebitDay = this.service.iDebitDay

      this.onSelectMonthDay()
    }
    this.form = new FormGroup({
      iBranchId: new FormControl("", Validators.required),
      iFromClassId: new FormControl("", Validators.required),
      iToClassId: new FormControl("", Validators.required),
      nAmount: new FormControl("", Validators.required),
      iDebitCount: new FormControl("", Validators.required),
      dDebitMonth: new FormControl("", Validators.required),
      nvComment: new FormControl("", Validators.required),
      iPaymentNum: new FormControl("", Validators.required),
      iStatusType: new FormControl("", Validators.required),
      iAssociationId: new FormControl(""),
      bInsertPayments: new FormControl(),
    });
    this.service.bfromDebit = false
    this.disabled = true
    // alert("dis" + this.disabled)
    this.gridOptions = {
      localeText: LOCALE_TEXT,
      context: { componentParent: this },
      defaultColDef: {
        resizable: true
    },
      enableRtl: true,
      onGridReady: this.onGridReady,
    };
      this.columnDefs = [
        {
          headerName: "פתיחה",
          field: "iFamilyId",
          width: 120,
          suppressFilter: true,
          cellRenderer: function (params) {
            var eDiv = document.createElement("div");
            eDiv.innerHTML = '<button mat-icon-button type="submit" class="btn-icon-grid" id="edit"><i class="icon-locked"></i></button>';
            var eButton = eDiv.querySelectorAll('#edit')[0];
            eButton.addEventListener('click', function () {
              params.context.componentParent.editFamily(params.data);
            });

            return eDiv;
          }
        },
        {
          headerName: "פירוט",
          field: "",
          width: 120,
          cellRenderer: function (params) {
            var eDiv = document.createElement("div");
            eDiv.innerHTML = '<button class="btn-icon-grid" id="edit"><i class="fa fa-ellipsis-v" aria-hidden="true"></i></button>';
            var eButton = eDiv.querySelectorAll('#edit')[0];
            eButton.addEventListener('click', function () {
              params.context.componentParent.monthDetails(params.data);


            });

            return eDiv;
          }
        },
        {
          headerName: "משפחה",
          field: "nvFamilyName",
          width: 150,
          filter: "agTextColumnFilter",
          sortable: true
        },


        {
          headerName: "יום חיוב",
          field: "nvDebitDay",
          width: 150,
          filter: "agTextColumnFilter",
          sortable: true
        },
        {
          headerName: "סכום",
          field: "nAmount",
          width: 150,
          filter: "agTextColumnFilter",
          sortable: true
        },
        {
          headerName: "סטטוס",
          field: "nvStatusType",
          width: 150,
          filter: "agTextColumnFilter"
        }]
    this.debitday = this.service.lDebitDay;
    if(this.gridOptions.api)
    this.gridOptions.api.setFilterModel(DebitComponent.debitFilterState);




  }
  rowDataChanged(event) {

    this.initCheckbox();
  }
  onGridReadyStudents(params) {
    this.gridApiStudents = params.api;
    this.gridColumnApiStudents = params.columnApi;
    params.api.sizeColumnsToFit();
  }
  onGridReady(params) {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;

    if (this.service != undefined) {
        if(DebitComponent.debitColState)
      this.gridOptions.columnApi.setColumnState(DebitComponent.debitColState);
      if(DebitComponent.debitGroupState[0])
      this.gridOptions.columnApi.setColumnGroupState(DebitComponent.debitGroupState);
      if(DebitComponent.debitSortState)
      this.gridOptions.api.setSortModel(DebitComponent.debitSortState);
      if(DebitComponent.debitFilterState)
      this.gridOptions.api.setFilterModel(DebitComponent.debitFilterState);
    }

    //  if(DebitComponent.debitColState)
    //  this.gridOptions.columnApi.setColumnState(DebitComponent.debitColState);
    //  if(DebitComponent.debitGroupState[0])
    //  this.gridOptions.columnApi.setColumnGroupState(DebitComponent.debitGroupState);
    //  if(DebitComponent.debitSortState)
    //  this.gridOptions.api.setSortModel(DebitComponent.debitSortState);
    //  if(DebitComponent.debitFilterState)
    //  this.gridOptions.api.setFilterModel(DebitComponent.debitFilterState);



    params.api.sizeColumnsToFit();

  }
  
  clearFiltering() {
    this.gridOptions.api.setRowData(this.rowData);
    let nodes = this.gridApi.getRenderedNodes();
    nodes.forEach(n => n.setSelected(false));
    let filteredRows=this.gridOptions.api.getModel().rootNode.childrenAfterFilter;
    this.numRows=0;
    this.sumTotal=0;
    filteredRows.forEach(t=>{
      this.sumTotal+=t.data.nAmount;
      this.numRows++;
    })
  }

  ngDoCheck(){
    if(this.debitAdditionByClass1.iFromClassId>0 && this.debitAdditionByClass1.iToClassId>0){
      let iFromcode= this.lClasses.filter(i => i.id == this.debitAdditionByClass1.iFromClassId);
      let iTocode= this.lClasses.filter(i => i.id == this.debitAdditionByClass1.iToClassId);

      if(iFromcode.length>0 && iTocode.length>0  && iFromcode[0].iCode>iTocode[0].iCode){
        this.form.controls["iFromClassId"].setErrors({'incorrect': true});
        this.form.controls["iToClassId"].setErrors({'incorrect': true})

      }



    }

    }

  GetMonthesCodeTable() {
    this.service.GetMonthesCodeTable(this.service.user.iInstitutionId).then(res => {

      if (res) {
        this.montes = res;
        this.montes.forEach(element => {
          if (element.dtMonth.getDate() == this.dDebitMonth.getDate() && element.dtMonth.getMonth() == this.dDebitMonth.getMonth()) {
            this.nvMonth = element.nvMonth;
            element.nvMonth = this.nvMonth;
          }

        });
      }

    }).catch(err => { console.log("err-----" + err); return false; })
  }

  GetMonthesCodeTableForUpdateReturn() {
  /*   this.tempDCreditMonth = this.dCreditMonth; */
    this.service.GetMonthesCodeTableForUpdateReturn(this.service.user.iUserId, this.service.user.nvGuide, this.service.user.iInstitutionId).then(
      res => {
        if (res) {
          this.montes = res;
        }
      }
    );
  }

  filterChanged(params) {
   let filteredRows=this.gridOptions.api.getModel().rootNode.childrenAfterFilter;
   this.numRows=0;
   this.sumTotal=0;
    filteredRows.forEach(t=>{
      this.sumTotal+=t.data.nAmount;
      this.numRows++;
    })
   }
   
  onSelectMonthDay() {
    this.sumTotal=0;
    this.numRows=0;
    if (this.dDebitMonth && this.iDebitDay) {
      if (this.montes)
        this.montes.forEach(element => {
          if (element.dtMonth.getDate() == this.dDebitMonth.getDate())
            this.nvMonth = element.nvMonth;
        });
      this.service.GetDebit(this.dDebitMonth, this.iDebitDay).then(res => {

        if (res) {
          this.rowData = res.lDebit;
          this.rowData.forEach(t=>{
            this.sumTotal+=t.nAmount;
            this.numRows++;
          })
          console.log(res)
          this.bIsFirstTime = res.bIsFirstTime
          this.bIsEnableDebit=res.bIsEnableDebit;


        }

      })
    }
  }


  GetCodeTablesByBranch() {
    return this.service.GetCodeTablesByBranch()
      .then(res => {
        console.log(res)

        this.lClasses=[]
        //get classes by branch
        for (let i = 0; i < res.length; i++) {
          if (res[i].nvTableName == "Classes") {
            for (let j = 0; j < res[i].lCodeTable.length; j++) {
              if (res[i].lCodeTable[j].iBranchId == this.debitAdditionByClass1.iBranchId) {
                this.lClasses.push({ iCode: res[i].lCodeTable[j].iCode, id: res[i].lCodeTable[j].id, text: res[i].lCodeTable[j].text })


              }
            }
          }
         }
         })
          .catch(err => { console.log("err-----" + err); return false; })
      }

  MakeDebit() {
    if (!this.bIsFirstTime)
     {
    this.fileNameDialogRef =this.mydialog.open(dailog, {
        hasBackdrop: true,
        height: '250px',
        width: '400px',
        data: "קימים חיובים חודשיים לחודש זה הפעלת תהליך זה תגרום למחיקת חיובים אלו וליצירת חיובים מחדש"

      });
      this.fileNameDialogRef.afterClosed().subscribe(result => {
        if (result === "ok") {
        this.service.InsDebit(this.dDebitMonth, this.iDebitDay).then(res => {

          if (res) {
           this.onSelectMonthDay();

          }
        }).catch(err => { console.log("err-----" + err); return false; })
      }
        });
    }
  else{
    this.service.InsDebit(this.dDebitMonth, this.iDebitDay).then(res => {

      if (res) {
       this.onSelectMonthDay();

      }

    }).catch(err => { console.log("err-----" + err); return false; })
  }}
  editFamily(Family) {
    this.service.currentiFamilyId = Family.iFamilyId
    this.service.currentFamily = Family;
    localStorage.setItem("family",JSON.stringify(this.service.currentFamily));
    DebitComponent.debitColState = this.gridOptions.columnApi.getColumnState();
    DebitComponent.debitGroupState = this.gridOptions.columnApi.getColumnGroupState();
    DebitComponent.debitSortState = this.gridOptions.api.getSortModel();
    DebitComponent.debitFilterState = this.gridOptions.api.getFilterModel();
    this.router.navigateByUrl('/header/families/tabs/parentDetails');
    this.service.bfromDebit = true
    this.service.visible = false
    this.service.dDebitMonth = this.dDebitMonth
    this.service.iDebitDay = this.iDebitDay
  }
  monthDetails(Family) {

    // this.isVisible = true
    // this.router.navigate(["debit-details"], { relativeTo: this.activatedRoute })
    // this.router.navigateByUrl('/header/debit/debit-details');

    this.service.currentiFamilyId = Family.iFamilyId
    console.log(this.service.currentiFamilyId)
    this.service.currentFamily = Family;


    this.detailsArr.push(this.dDebitMonth, this.iDebitDay, this.nvMonth)
    this.dialogRef = this.debitDetaildDailog.open(DebitDetailsComponent, {
      hasBackdrop: true,
 height: '800px',
      width: '1800px',
      data: this.detailsArr
    });
  }

  debitAdditionByClass() {
    this.debitAdditionByClass1=new DebitAdditionByClass()
    this.isVisiblePopup = true;
    this.isForStudents=false;
  }
  InsDebitAdditionByClass(){
    let lChildren=[];
    if(this.rowDataStudents[0]){
       lChildren= this.gridOptionsStudents.api.getSelectedRows().map(c=>c.iChildId);
    }
      return this.service.InsDebitAdditionByClass(this.debitAdditionByClass1,lChildren)
      .then(res => {
        this.isVisiblePopup = false;
        this.rowDataStudents=[]
      })
     .catch(err => { console.log("err-----" + err); return false; })

    }

  CalculateDiscounts(){
    return this.service.CalculateDiscounts()
    .then(res => {

    })
   .catch(err => { console.log("err-----" + err); return false; })
  }
  isForStudents:boolean=false;
  showGridStudents(){
    this.isForStudents=true;
    if(this.debitAdditionByClass1.iBranchId&&this.debitAdditionByClass1.iFromClassId&&this.debitAdditionByClass1.iToClassId){
       this.service.GetChildrenListForDebitAdditionByClass(this.debitAdditionByClass1.iBranchId,this.debitAdditionByClass1.iFromClassId,this.debitAdditionByClass1.iToClassId).then(res=>{
        console.log(res);
        this.rowDataStudents=res;
    });
    }

  }
  onSelectionChanged(params) {
    params.api.selectionController.lastSelectedNode.data.isChecked = !params.api
      .selectionController.lastSelectedNode.data.isChecked;
  }
  initCheckbox() {
    if (this.gridApiStudents) {
      const component = this;
      let nodes = this.gridApiStudents.getRenderedNodes();
      this.gridOptionsStudents.api.selectAll()
    }
  }
}
