import { Tasks } from './../../Models/Tasks';
import { Component, OnInit } from '@angular/core';
import { LOCALE_TEXT } from '../../app/Additions/constants';
import { ServService } from '../../app/serv.service';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import {dailog} from '../../app/Additions/dailog/dailog'
import * as moment from "moment"

@Component({
  selector: 'app-family-tasks',
  templateUrl: './family-tasks.component.html',
  styleUrls: ['./family-tasks.component.css']
})
export class FamilyTasksComponent implements OnInit {

  backgroundColor: string;
  public columnDefs;
  public rowData;
  public gridOptions;
  public gridColumnApi: any;
  public gridApi: any;
  public columnTypes;

  isVisible = false;
  isVisibleDeletePopup: boolean = false;
  iTaskToDel: number;
  task: Tasks;
  form: FormGroup;
  alertResponse = '';
  displayAlert = false;
  isEdit = false;
  iBackgroundColor: number;

  constructor(public serv: ServService, private dailog: MatDialog) {
    this.task = new Tasks();
    this.backgroundColor = this.serv.backgroundColor[0];
  }

  ngOnInit() {


    this.gridOptions = {
      localeText: { LOCALE_TEXT },
      context: { componentParent: this },
      defaultColDef: {
        resizable: true
    },
      // onGridReady: this.onGridReady,
      enableRtl: true
    },
      this.columnDefs = [
        {
          headerName: "מחיקה",
          width: 40,
          suppressFilter: true,
  
          cellRenderer: function (params) {
            var eDiv = document.createElement("div");
            eDiv.innerHTML =
              '<button mat-icon-button type="button" class="btn-icon-grid" id="edit"><i class="icon-garbage"></i></button>';
            var eButton = eDiv.querySelectorAll("#edit")[0];
            eButton.addEventListener("click", function () {
              params.context.componentParent.DelTask(params.data);
            });
            return eDiv;
          }
        },
        {
          headerName: "עריכה",
          field: "iUserId",
          width: 40,
          cellRenderer: function (params) {
            var eDiv = document.createElement("div");
            eDiv.innerHTML = '<button class="btn-icon-grid" id="edit"><span class="icon-edit"></span></button>';
            var eButton = eDiv.querySelectorAll('#edit')[0];
            eButton.addEventListener('click', function () {
              params.context.componentParent.editTask(params.data);
            });

            return eDiv;
          }
        },     
        {
          headerName: "תאריך עדכון",
          field: "dtLastModifyDate2",
          width: 60,
          filter: "agDateColumnFilter",
          type: "dateColumn",
          comparator: this.dateComparator,
          sortable: true
        },        
        {
          headerName: "שם מעדכן",
          field: "nvCreatedByUser",
          width: 60,
          sortable: true
        },
        {
          headerName: "תאריך יעד",
          field: "dTargetDate2",
          width: 60,
          filter: "agDateColumnFilter",
          comparator: this.dateComparator,
          sortable: true
        },
        {
          headerName: "מיועד עבור",
          field: "nvTargetUser",
          width: 60,
          sortable: true
        },
        {
          headerName: "פרטים",
          field: "nvComment",
          width: 300,
          filter: "agTextColumnFilter",
          sortable: true
        },
        {
          headerName: "בוצע",
          field: "nvDone",
          width: 40,
          filter: "agTextColumnFilter",
          sortable: true
        },
        {
          headerName: "בוצע עי",
          field: "nvDoneByUser",
          width: 60,
          filter: "agTextColumnFilter",
          sortable: true
        }]

    this.GetTasks();
    this.GetBackgroundColor();

    this.form = new FormGroup({
      dTargetDate: new FormControl(),
      nvComment: new FormControl(),
      bDone: new FormControl(),
      nvDone: new FormControl(),
      iTargetUserID: new FormControl()
    });


  }

  onGridReady(params) {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;

    params.api.sizeColumnsToFit();
  }

  GetTasks() {
    this.serv.GetTasks().then(res => {
      for (let i = 0; i < res.length; i++) {
        if(res[i].dTargetDate!=null){
         res[i].dTargetDate2 = moment(res[i].dTargetDate).format("DD/MM/YYYY");
        console.log(res[i].dTargetDate);
        }
        if(res[i].dtLastModifyDate!=null){
          res[i].dtLastModifyDate2 = moment(res[i].dtLastModifyDate).format("DD/MM/YYYY");
        //  console.log(res[i].dtLastModifyDate);
         }
      }
      this.rowData = res;
       // console.log("res " + res);  
      if (res.iBackgroundColor!=null) {
        this.iBackgroundColor = res.iBackgroundColor
      } else {
        this.iBackgroundColor = 0
      }
    });
  }

  GetBackgroundColor(){
    this.serv.GetBackgroundColor().then(res => { 
      console.log(res[0].iBackgroundColor)
      if (res[0].iBackgroundColor!=null) {
        this.iBackgroundColor = res[0].iBackgroundColor
      } else {
        this.iBackgroundColor = 0
      }
    });
  }

  addTask() {
    this.task = new Tasks();
    this.isVisible = true;
  }

  InsUpdTasks() {
    if (this.form.valid == false) {
      this.dailog.open(dailog, {
        hasBackdrop: true,
        height: "200px",
        width: "300px",
        data: "יש למלא את כל שדות החובה"
      });
      return;
    }
    // if(this.form.controls)
    // console.log(this.form.controls)
    let containsValue=false;
      Object.keys(this.form.controls).forEach(key=>{
        if(this.form.controls[key].value)
            {
              containsValue=true;

            }
      })
    if(!containsValue){
      this.isVisible = false;
      return;
    }

    if (!this.isEdit) this.task.iTaskId = -1;
    this.task.dtLastModifyDate = new Date();
    this.serv.InsUpdTasks(this.task).then(res => {
      if (res) {
        if (!this.isEdit) {
          this.GetTasks();
          this.isEdit = false;
        }
        this.isVisible = false;
        this.gridApi.setRowData(this.rowData);
        this.GetTasks();
      }
    });
  }


  editTask(params) {
    this.isEdit = true;
    this.isVisible = true;
    this.task = params;
  }

  DelTask(params){
    this.isVisibleDeletePopup = true;
    this.iTaskToDel = params.iTaskId;
  }

  DeleteTask() {
    this.serv.DeleteTask(this.iTaskToDel)
      .then(res => {
        this.dailog.open(dailog, {
          hasBackdrop: true,
          height: "200px",
          width: "300px",
          data: "התזכורת נמחקה בהצלחה",
        });
        this.GetTasks();
      });
  }

  dateComparator(date1, date2) {
    if (
      (date1 === null && date2 === null) ||
      (typeof date1 == "undefined" && typeof date2 == "undefined")
    ) {
      return 0;
    }
    if (date1 === null || typeof date1 == "undefined") {
      return -1;
    }
    if (date2 === null || typeof date2 == "undefined") {
      return 1;
    }
    let dateParts1 = date1.split("/");
    let cellDate1 = new Date(
      Number(dateParts1[1]) +
      "/" +
      Number(dateParts1[0]) +
      "/" +
      Number(dateParts1[2])
    );
    let dateParts2 = date2.split("/");
    let cellDate2 = new Date(
      Number(dateParts2[1]) +
      "/" +
      Number(dateParts2[0]) +
      "/" +
      Number(dateParts2[2])
    );
    return cellDate1.getTime() - cellDate2.getTime();
  }
  
  onSelectBackgroundColor() {
    this.serv.UpdBackgroundColor(this.iBackgroundColor, this.serv.currentiFamilyId).then(res => {
      if (res == "success") {
        this.dailog.open(dailog, {
          hasBackdrop: true,
          height: "200px",
          width: "300px",
          data: "נשמר בהצלחה!"
        });
      } else if (res == "error") {
        this.dailog.open(dailog, {
          hasBackdrop: true,
          height: "200px",
          width: "300px",
          data: "ארעה שגיאה בלתי צפויה"
        });
      } else {
        this.dailog.open(dailog, {
          hasBackdrop: true,
          height: "200px",
          width: "300px",
          data: res
        });
      }
    });
  }

  onSelectTargetUserID(event){
    this.task.iTargetUserID = event.value;
  }
}
