import { Component, OnInit } from "@angular/core";
import "ag-grid-enterprise";
import { LOCALE_TEXT } from "../../app/Additions/constants";
import { Router, NavigationEnd } from "@angular/router";
import { ServService } from "../../app/serv.service";
import { Family } from "../../Models/Family";
import { AppProxy } from "../../app/app.proxy";
import { ImportToExcel } from "../../Models/ImportToExcel";
import { MatDialog } from "@angular/material/dialog";
import { dailog } from "src/app/Additions/dailog/dailog";
import { MatCheckboxComponent } from 'src/app/Additions/mat-checkbox/mat-checkbox/mat-checkbox.component';
import * as moment from "moment";
import { DataToImport } from "src/Models/DataToImport";
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { CodeTable } from "../../Models/CodeTable";

@Component({
  selector: "app-families",
  templateUrl: "./families.component.html",
  styleUrls: ["./families.component.scss"]
})
export class FamiliesComponent implements OnInit {
  ExcelObj: {
    nvFamilyName: string;
    nvPhone: string;
    iDebitDay: string;
    nvCityName: string;
    nvAddress: string;
    nvNumHouse: string;
    nvNeighborhood: string;
    nvZipCode: string;
    nvLastNameF: string;
    nvFirstNameF: string;
    bIsParentHeadF: string;
    nvIdentityF: string;
    iIdentityTypeF: string;
    nvMobileF: string;
    nvWorkPlaceF: string;
    nvWorkAddressF: string;
    bIsParentHeadM: string;
    nvLastNameM: string;
    nvFirstNameM: string;
    nvIdentityM: string;
    iIdentityTypeM: string;
    nvMobileM: string;
    nvWorkPlaceM: string;
    nvWorkAddressM: string;
    iBankType: string;
    iBranchNumber: string;
    iaccountNumber: string;
    nvAccountName: string;
    nMaxAmount: string;
    nvIdentityC: string;
    iIdentityTypeC: string;
    nvFirstNameC: string;
    dBirthDate: string;
    nvJewBirthDate: string;
    dRegisteredDate: string;
    dBeginDate: string;
    dEndDate: string;
    nvBranchName: string;
    nvLevel: string;
    nTuitionAmount: string;
    nDiscountPerc: string;
    nDiscountAmount: string;
    nTransAmount: string;
    nMealsAmount: string;
    nvTranspGoType: string;
    nvTranspReturnType: string;
    nvTranspReturnFriType: string;
    bDiscSet: string;
    nvAccountIdentity: string;
    nvCardLastFourDigits: string;
    nvTokenNumber: string;
    nvTuitionRateName: string;
    nvTransRateName: string;
    nvEtraRateName: string;
    nvKupatCholim: string;
    dBirthDateF: string;
    dBirthDateM: string;
  };
  iYearId: number;
  isVisible: boolean = false;
  isVisibleWarningPopup: boolean= false;
  default;
  type: string;
  name: string;
  url: string;
  iDataToImportID: number = 0;
  public backgroundColor: string;
  private column;
  private column1;
  private rowData;
  public rowData1;
  private gridOptions;
  private gridOptions1;
  private gridColumnApi: any;
  private gridApi: any;
  private families: Array<Family>;

  currentiFamilyIdTemp;
  ChildToImport: DataToImport;
  form: FormGroup;
  ImportToExcel: ImportToExcel;
  lImportToExcel: ImportToExcel[];
  routerSub: any;
  lStatus: Array<number>;
  isAllChecked: boolean = false;
  temp = false;
  public dailog: MatDialog;
  isFilter: boolean = true;
  private newData: Array<any> = new Array<any>();
  familyToDelete = null;
  isVisibleDeletePopup: boolean = false;
  isVisibleAttachImportData: boolean = false;
  displayAlert: boolean = false;
  alertResponse: string[] = [];
  ExcelNumbers: string[];
  ExcelPhones: string[];
  ExcelRequired: string[];
  lFamilies: Array<CodeTable>;
  lImports: Array<CodeTable>;

  ngOnDestroy() {
    this.serv.currentiFamilyId = this.currentiFamilyIdTemp;
    this.routerSub.unsubscribe();
  }

  constructor(private router: Router, public serv: ServService, private dialog: MatDialog) {
    this.ImportToExcel = new ImportToExcel();
    this.lImportToExcel = [];
    this.ChildToImport = new DataToImport();
    this.ExcelObj = {
      nvFamilyName: "שם משפחה",
      nvPhone: "טל",
      iDebitDay: "יום חיוב",
      nvCityName: "עיר",
      nvAddress: "כתובת",
      nvNumHouse: "בית",
      nvNeighborhood: "שכונה",
      nvZipCode: "מיקוד",
      nvLastNameF: "שם משפחה אב",
      nvFirstNameF: "שם פרטי אב",
      bIsParentHeadF: "ראש משפחה",
      nvIdentityF: "מספר מזהה אב",
      iIdentityTypeF: "סוג מזהה אב",
      nvMobileF: "נייד אב",
      dBirthDateF: "תאריך לידה אב",
      nvWorkPlaceF: "מקום עבודה אב",
      nvWorkAddressF: "כתובת עבודה אב",
      bIsParentHeadM: "ראש משפחה אם",
      nvLastNameM: "שם משפחה אם",
      nvFirstNameM: "שם פרטי אם",
      nvIdentityM: "מספר מזהה אם",
      iIdentityTypeM: "סוג מזהה אם",
      nvMobileM: "נייד אם",
      dBirthDateM: "תאריך לידה אם",
      nvWorkPlaceM: "מקום עבודה אם",
      nvWorkAddressM: "כתובת עבודה אם",
      iBankType: "מס' בנק",
      iBranchNumber: "מס' סניף",
      iaccountNumber: "מס' חשבון",
      nvAccountName: "שם בעל חשבון",
      nMaxAmount: "סכום מקסימלי",
      nvIdentityC: "מס' מזהה ילד",
      iIdentityTypeC: "סוג מזהה ילד",
      nvFirstNameC: "שם פרטי ילד",
      dBirthDate: "תאריך לידה ילד",
      nvJewBirthDate: "תאריך לידה עברי ילד",
      dRegisteredDate: "תאריך רישום",
      dBeginDate: "תאריך התחלה",
      dEndDate: "תאריך סיום",
      nvBranchName: "סניף",
      nvLevel: "כיתה",
      nTuitionAmount: "מחיר שכ'ל",
      nDiscountPerc: "הנחה (אחוז)",
      nDiscountAmount: "הנחה (סכום)",
      nTransAmount: "מחיר הסעות",
      nMealsAmount: "מחיר אוכל",
      nvTranspGoType: "הסעות הלוך",
      nvTranspReturnType: "הסעות חזור",
      nvTranspReturnFriType: "הסעות חזור שישי",
      bDiscSet: "הנחה קבועה",
      nvAccountIdentity: "ת'ז בעל הכרטיס",
      nvCardLastFourDigits: "4 ספרות אחרונות",
      nvTokenNumber: "טוקן",
      nvTuitionRateName: "תעריף שכ'ל",
      nvTransRateName: "תעריף הסעות",
      nvEtraRateName: "תעריף נוסף",
      nvKupatCholim: "קופ'ח",
    };
    this.ExcelRequired = [
      "nvFamilyName",
      "nvPhone",
      "nvCityName",
      "nvAddress",
      "nvLastNameF",
      "nvFirstNameF",
      "nvIdentityF",
      "nvMobileF",
      "nvLastNameM",
      "nvFirstNameM",
      "nvIdentityM",
      "nvMobileM",
      "nvIdentityC",
      "nvFirstNameC",
      "nvLevel",
      "nvBranchName"
    ];
    this.ExcelNumbers = [
      "iDebitDay",
      "iIdentityTypeF",
      "iIdentityTypeM",
      "iBankType",
      "iBranchNumber",
      "iaccountNumber",
      "nMaxAmount",
      "iIdentityTypeC",
      "nDiscountPerc",
      "nDiscountAmount",
      "nvCardLastFourDigits"
    ];
    this.ExcelPhones = [
      "nvPhone",
      "nvMobileF",
      "nvMobileM"
    ];
    this.lStatus = new Array<number>();
    this.lFamilies = new Array<CodeTable>();
    this.lImports = new Array<CodeTable>();
    this.backgroundColor = this.serv.backgroundColor[0];
    this.routerSub = router.events.subscribe(val => {
      if (val instanceof NavigationEnd) {
        if (location.pathname.indexOf("parentDetails") == -1) {
          this.isFilter = !this.isFilter;
        } else this.serv.visible = true;
      }
    });
  }

  ngOnInit() {
    if (localStorage.getItem("familyStatuses") !== null) {
      this.lStatus = JSON.parse(localStorage.getItem("familyStatuses"));
    }
    this.gridOptions = {
      localeText: LOCALE_TEXT,
      context: { componentParent: this },
      enableRtl: true,
      suppressRowClickSelection: true,
      rowClassRules: {
        redbackground: function (params) {
          return params.data.iBackgroundColor === 1080;
        },
        orengebackground: function (params) {
          return params.data.iBackgroundColor === 1081;
        },
        yellowbackground: function (params) {
          return params.data.iBackgroundColor === 1082;
        },
        whitebackground: function (params) {
          return params.data.iBackgroundColor === 1083;
        },
        greenbackground: function (params) {
          return params.data.iBackgroundColor === 1084;
        },
        bluebackground: function (params) {
          return params.data.iBackgroundColor === 1085;
        },
      },
      onGridReady: this.onGridReady,
      defaultColDef: {
        width: 50,
        resizable: true

      }
    }
    this.column = [
      {
        headerName: "",
        field: "checkbox",
        checkboxSelection: true,
        suppressFilter: true,
        menuTabs: [],
        width: 50,
        // headerComponentFramework:MatCheckboxComponent,
        // cellRendererFramework: MatCheckboxComponent,
        headerCheckboxSelection: true
      },
      {
        headerName: "",
        width: 80,
        suppressFilter: true,
        cellRenderer: function (params) {
          if (params.data.bDeleteOptional == true) {
            var eDiv = document.createElement("div");
            eDiv.innerHTML =
              '<button mat-icon-button type="submit" class="btn-icon-grid" id="edit"><i class="icon-garbage"></i></button>';
            var eButton = eDiv.querySelectorAll("#edit")[0];
            eButton.addEventListener("click", function () {
              params.context.componentParent.isVisibleDeletePopup = true;
              params.context.componentParent.familyToDelete = params.data;
            });

            return eDiv;
          }
        }
      },
      {
        headerName: "פתיחה",
        width: 90,
        suppressMenu: true,
        suppressFilter: true,
        cellRenderer: function (params) {

          var eDiv = document.createElement("div");
          eDiv.innerHTML =
            '<button mat-icon-button type="submit" class="btn-icon-grid" id="edit"><i class="icon-locked"></i></button>';
          var eButton = eDiv.querySelectorAll("#edit")[0];
          eButton.addEventListener("click", function () {
            params.context.componentParent.editFamily(params.data);
          });

          return eDiv;
        }
      },
      {
        headerName: "שם משפחה ",
        field: "nvFamilyName",
        width: 155,
        filter: "agTextColumnFilter",
        sortable: true
      },
      {
        headerName: "שם אב",
        field: "nvFatherName",
        width: 145,
        filter: "agTextColumnFilter",
        sortable: true
      },
      {
        headerName: "שם אם",
        field: "nvMotherName",
        width: 145,
        filter: "agTextColumnFilter",
        sortable: true
      },
      {
        headerName: "כתובת",
        field: "nvAddress",
        width: 220,
        filter: "agTextColumnFilter",
        sortable: true
      },
      {
        headerName: "נייד אב",
        field: "nvPhoneF",
        width: 155,
        filter: "agTextColumnFilter",
        sortable: true
      },
      {
        headerName: "נייד אם",
        field: "nvPhoneM",
        width: 155,
        filter: "agTextColumnFilter",
        sortable: true
      },
      {
        headerName: "טלפון",
        field: "nvPhone",
        width: 155,
        filter: "agTextColumnFilter",
        sortable: true
      },
      {
        headerName: "צבע",
        field: "nvBackgroundColor",
        width: 80,
        filter: "agTextColumnFilter",
        sortable: true
      },
    ];
    if (this.serv.user.iUserType != 42) {
      this.column.push({
        headerName: "יתרה נוכחית",
        field: "nBalance",
        width: 110,
        filter: "agNumberColumnFilter",
        sortable: true
      })
    }
    this.getFamilys();
    this.serv.visibleStatus = true;

    this.form = new FormGroup({
      iDataToImportID: new FormControl(),
      iFamilyIdToAttach: new FormControl(),
      bUpdateDetails: new FormControl(),
      iImportIdToAttach: new FormControl(),
        nvFamilyToAttach:new FormControl(),
        nvFamilyName:new FormControl(),
        nvPhone:new FormControl(),
        nvCityName:new FormControl(),
        nvAddress:new FormControl(),
        nvNumHouse:new FormControl(),
        nvNeighborhood:new FormControl(),
        nvFirstNameF:new FormControl(),
        nvIdentityF:new FormControl(),
        nvMobileF:new FormControl(),
        nvFirstNameM:new FormControl(),
        nvIdentityM:new FormControl(),
        nvMobileM:new FormControl(),
        nvFirstNameC:new FormControl(),
        nvIdentityC:new FormControl()
    });
    
  }

  onGridReady(params) {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
    if (this.serv != undefined) {
      // console.log(this.serv.FilterFamiliesArray);
      if (this.serv.FilterFamiliesArray.length > 0)
        this.serv.FilterFamiliesArray.forEach(element => {
          this.gridOptions.api.setFilterModel(element);
        });
      if (this.serv.SortFamiliesArray.length > 0)
        this.serv.SortFamiliesArray.forEach(element => {
          this.gridOptions.api.setSortModel(element);
        });
      if (this.serv.CheckBoxFamiliesArray.length > 0) {
        this.newData = this.serv.CheckBoxFamiliesArray;
        this.gridApi.setRowData(this.newData);
        let nodes = this.gridApi.getRenderedNodes();
        nodes.forEach(n => n.setSelected(true));
      }
    }
    params.api.sizeColumnsToFit();
  }

  getFamilys() {
    this.serv.getFamilys(this.lStatus).then(res => {
      for (let i = 0; i < res.length; i++) {
        res[i].isChecked = false;
      }
      this.families = res;
      this.rowData = this.families;      
    });
    console.log(this.serv.user.bReadOnly)
  }

  lSelectedRows = [];
  onSelectionChanged(params) {
    this.lSelectedRows = params.api.getSelectedRows();
    // params.api.selectionController.lastSelectedNode.data.isChecked = !params.api
    //   .selectionController.lastSelectedNode.data.isChecked;
  }

  clearFiltering() {
    this.serv.FilterFamiliesArray.forEach(element => {
      this.gridOptions.api.setFilterModel("");
    });
    this.serv.SortFamiliesArray.forEach(element => {
      this.gridOptions.api.setSortModel("");
    });
    this.serv.CheckBoxFamiliesArray = [];
    this.gridOptions.api.setRowData(this.rowData);
    // this.initCheckbox();
    let nodes = this.gridApi.getRenderedNodes();
    nodes.forEach(n => n.setSelected(false));
  }

  filterChanged(params) {
    params.api.getSelectedRows();
    let t = this.gridOptions.api.getFilterModel();
    this.serv.FilterFamiliesArray = [];
    this.serv.FilterFamiliesArray.push(t);
  }

  sortChanged() {
    let t = this.gridOptions.api.getSortModel();
    this.serv.SortFamiliesArray.push(t);
  }

  editFamily(Family) {
    this.serv.currentiFamilyId = Family.iFamilyId;
    this.serv.currentFamily = Family;
    localStorage.setItem("family", JSON.stringify(this.serv.currentFamily));
    localStorage.setItem("currentiFamilyId", JSON.stringify(Family.iFamilyId));
    this.router.navigateByUrl("/header/families/tabs/parentDetails");
    this.serv.visible = false;
  }

  addFamily() {
    this.serv.currentFamily = new Family();
    this.serv.visible = false;
    this.serv.currentiFamilyId = -1;
    localStorage.setItem("family", JSON.stringify(this.serv.currentFamily));
    this.router.navigate(["/header/families/tabs/parentDetails"]);
  }

  onClickAll() {
    if (!this.isAllChecked) {
      this.isAllChecked = true;
      this.temp = true;
      this.lStatus = this.lStatus.slice(0, -this.lStatus.length);
      for (let i = 0; i < this.serv.lFamilyStatusType.length; i++) {
        this.lStatus[i] = this.serv.lFamilyStatusType[i].id;
      }
      this.lStatus.push(0);
    } else if (this.isAllChecked) {
      this.temp = false;
      this.isAllChecked = false;
      this.lStatus = this.lStatus.slice(0, -this.lStatus.length);
    }
    localStorage.setItem("familyStatuses", JSON.stringify(this.lStatus));

    setTimeout(() => {
      this.getFamilys();
    }, 100);
  }

  onSelectStatus(event) {
    var tempArr: Array<number> = new Array<number>();
    //אם לחצן הכל לחוץ אך הוסר סטטוס אחד, יש להסיר את הלחיצה מכפתור הכל
    if (
      event.value[0] == "0" &&
      this.lStatus.length == this.serv.lFamilyStatusType.length &&
      this.temp
    ) {
      this.temp = false;
      this.isAllChecked = false;
      for (let i = 1; i < this.lStatus.length; i++) {
        if (this.lStatus[i] > 0) {
          tempArr.push(this.lStatus[i]);
        }
      }
      this.lStatus = this.lStatus.slice(0, -this.lStatus.length);
      this.lStatus = tempArr;
    }
    localStorage.setItem("familyStatuses", JSON.stringify(this.lStatus));

    this.getFamilys();
  }

  downLoadExcel() {
    var link = document.createElement("a");
    link.download = "יבוא ילדים" + ".xlsx";
    link.href =
      AppProxy.baseUrl + "Files/excelfilesForExample/תבנית יבוא ילדים.xlsx";
    link.click();
  }

  onFileGroupsChange(evt: any) {
    
    const component = this;
    component.lImportToExcel = [];

    this.serv.ReadFromExcel(evt.target, function (data: any) {
      //קריאה מאקסל
      let i = 1;
      let dataLength = data.length;
      console.log("data", data);
      console.log("ImportToExcel", component.ImportToExcel);
      console.log("ExcelObj", component.ExcelObj)
      // var duplicates = Object.keys(data[0]);
      // data.forEach(d => {
      //   duplicates = duplicates.concat(Object.keys(d))
      // });
      // var uniques = Array.from(new Set(duplicates.map((item: any) => item)));
      component.alertResponse = [];
      var allUniques = Object.values(data[0]);
      var absences = Object.values(component.ExcelObj).filter(f => !allUniques.includes(f));
      var uniques = allUniques.filter(u => !Object.values(component.ExcelObj).includes(u as string));
      if (uniques.length > 0 || absences.length > 0) {//|| absences.length > 0
        if (uniques.length > 0) {
          component.alertResponse.push("ישנן כותרות של שדות שאינן חוקיות: ");
          uniques.forEach(u => component.alertResponse.push(u.toString()))
        }
        if (absences.length > 0) {
          component.alertResponse.push("חסרות העמודות הבאות: ");
          absences.forEach(a => component.alertResponse.push(a))
        }
        component.dialog.open(dailog, {
          hasBackdrop: true,
          height: "200px",
          width: "300px",
          data: component.alertResponse
        });
      }
      else {
        try {
          let dataErrors: string[] = new Array<string>(dataLength);
          let defaults: string = "";
          let error = false;
          dataErrors = dataErrors.fill('');
          //מעבר על השורות לשם בדיקה שהתחביר תקין
          while (i < dataLength) {
            let row = data[i];
            component.ImportToExcel = new ImportToExcel();
            for (let key in component.ImportToExcel) {
              if (row[allUniques.indexOf(component.ExcelObj[key]).toString()]) {
                if (!component.checkValid(key, row[allUniques.indexOf(component.ExcelObj[key]).toString()])) {
                  dataErrors[i] += component.ExcelObj[key] + ", ";
                  error = true;
                } else if (key == 'nvIdentityM') {
                  if (isNaN(+row[allUniques.indexOf(component.ExcelObj[key]).toString()]))
                    row[allUniques.indexOf(component.ExcelObj['iIdentityTypeM']).toString()] = 4;
                }
                else if (key == 'iaccountNumber') {
                  if (row[allUniques.indexOf(component.ExcelObj[key]).toString()].length > 9) {
                    dataErrors[i] += component.ExcelObj[key] + ", ";
                    error = true;
                  }
                }
                else if (key == 'nvIdentityF') {
                  if (isNaN(+row[allUniques.indexOf(component.ExcelObj[key]).toString()]))
                    row[allUniques.indexOf(component.ExcelObj['iIdentityTypeF']).toString()] = 4;
                }
                else if (key == 'nvIdentityC') {
                  if (isNaN(+row[component.ExcelObj[key]]))
                    row[allUniques.indexOf(component.ExcelObj['iIdentityTypeC']).toString()] = 4;
                }
                else if (key == "nMaxAmount")
                  component.ImportToExcel[key] = Number(
                    row[allUniques.indexOf(component.ExcelObj[key]).toString()]
                  );
                // else {
                if (component.ExcelNumbers.includes(key)) {
                  component.ImportToExcel[key] = parseInt(row[allUniques.indexOf(component.ExcelObj[key]).toString()]).toString();
                } else {
                  component.ImportToExcel[key] = row[allUniques.indexOf(component.ExcelObj[key]).toString()];
                }
                // }
              } else {
                if (component.ExcelRequired.includes(key)) {
                  dataErrors[i] += component.ExcelObj[key] + ", ";
                  error = true;
                }
              }


            }
            component.ImportToExcel.iRowNumber = i;
            component.lImportToExcel.push(component.ImportToExcel);

            i++;
          }
          component.serv.CheckInsFromExcel(component.lImportToExcel, component.iYearId).then(res => {
            if (res) {
              if (res.lErrorRows.length > 0 || res.lDefault.length > 0 && res.lDefault[0] != "") {
                error = true;
                if (res.lErrorRows.length > 0) {
                  res.lErrorRows.forEach(r => {
                    r.lCells.forEach(c => { dataErrors[r.iRownumber] += component.ExcelObj[c] + ", "; })
                  })
                }
                if (res.lDefault.length > 0 && res.lDefault[0] != "") {
                  res.lDefault.forEach(d => defaults += d + ", ");
                }

              } if (error) {
                console.log(dataErrors)
                let errString = [];
                if (dataErrors.length > 0 && !(dataErrors.length == 1 && dataErrors[0] != "")) {
                  errString.push("ישנם ערכים שאינם תקינים בשורות הבאות:");
                  let i = 1;
                  for (; i < dataErrors.length; i++) {
                    if (dataErrors[i] && dataErrors[i] != "")
                      errString.push("שורה " + (i + 1) + ": "); errString.push(dataErrors[i]);
                  }
                }
                if (defaults.length > 0) {
                  errString.push("חסרים ערכי ברירת המחדל הבאים:"); errString.push(defaults);
                }

                component.dialog.open(dailog, {
                  hasBackdrop: true,
                  height: "500px",
                  width: "700px",
                  data: errString
                });
              } else {
                component.serv
                  .ImportFromExcelToReview(component.lImportToExcel, component.iYearId)
                  .then(res => {
                    if (res > 0) {
                      alert("בוצע בהצלחה");
                      component.GetDataToReview();
                    } else alert("היתה בעיה ביבוא הנתונים");
                  });
              }

            }
            else
              alert("היתה בעיה ביבוא הנתונים");

          })
        } catch (error) {
          alert(error);
          return;
        }
      }
    });
  }

  dataExport() {
    this.serv.dataExport().then(res => {
      console.log(res);

      var link = document.createElement("a");
      link.setAttribute("id", "excelDownload");
      link.style.visibility = "hidden";
      link.download = res;
      link.href = AppProxy.baseUrl + "Files/Excel/" + res;
      link.click();
    });
  }

  onFilterCheckBox() {
    // if (this.isFilter == true) {
    this.isFilter = false;
    this.newData = new Array<any>();
    // this.rowData.forEach(element => {
    //   if (element.isChecked == true) {
    //     this.newData.push(element);
    //   }
    // });
    this.newData = this.lSelectedRows;
    this.gridOptions.api.setRowData(this.newData);
    this.serv.CheckBoxFamiliesArray = this.newData;
    this.initCheckbox();
    // } else {
    //   this.isFilter = true;
    //   this.gridOptions.api.setRowData(this.rowData);
    //   this.initCheckbox();
    // }
  }

  initCheckbox() {
    if (this.gridApi) {
      const component = this;
      let nodes = this.gridApi.getRenderedNodes();
      nodes.forEach(function (node: any) {
        node.setSelected(node.data.isChecked);
      });
    }
  }

  DeleteFamily(family) {
    this.serv.DeleteFamily(family.iFamilyId).then(res => {
      this.rowData.splice(this.rowData.indexOf(family), 1);
      this.gridApi.setRowData(this.rowData);
    });
  }

  checkValid(key: string, value: string) {
    if (value.length > 100)
      return false;
    if (key.startsWith('d')) {
      // if(value.includes('.'))
      // {
      //   value=value.split('.').join('/');
      // }
      let arr = value.split('/');
      if (arr.length == 1)
        return false;
      if (arr[0].length == 1)
        arr[0] = '0' + arr[0];
      if (arr[1].length == 1)
        arr[1] = '0' + arr[1];
      value = arr[0] + '/' + arr[1] + '/' + arr[2];
      return moment(value, "DD/MM/YYYY", true).isValid();;
    } else {
      if (this.ExcelNumbers.includes(key)) {
        return !isNaN(parseInt(value));
      } else {
        if (this.ExcelPhones.includes(key)) {
          value = value.replace('-', '');
          if (value.length > 11)
            return false;
          if (!value.startsWith('0'))
            return false;
          return !isNaN(+value);
        } else { return true; }
      }
    }
  }

  dataImport() {
    this.isVisible = true;
    this.iYearId = this.serv.iYearId;

    this.gridOptions1 = {
      localeText: LOCALE_TEXT,
      context: { componentParent: this },
      enableRtl: true,
      suppressRowClickSelection: true,
      onGridReady: this.onGridReady1,
      defaultColDef: {
        width: 50,
        resizable: true
        }
    }
    this.column1 = [
      {
        headerName: "פתיחה",
        width: 60,
        suppressMenu: true,
        suppressFilter: true,
        cellRenderer: function (params) {

          var eDiv = document.createElement("div");
          eDiv.innerHTML =
            '<button mat-icon-button type="submit" class="btn-icon-grid" id="edit"><i class="icon-locked"></i></button>';
          var eButton = eDiv.querySelectorAll("#edit")[0];
          eButton.addEventListener("click", function () {
            params.context.componentParent.AttachImportData(params.data);
          });

          return eDiv;
        }
      },
 /*       {
        headerName: "שורה",
        field: "indexRow",
        cellRenderer: function (params) {
          let z = "<p>" + (params.node.rowIndex + 1) + "</p>";
          return z;
        },
        width: 100,
        filter: "agNumberColumnFilter"
      }, */
      {
        headerName: "צרף למשפחת",
        field: "nvFamilyToAttach",
        width: 160,
        filter: "agTextColumnFilter",
        sortable: true
      },
      {
        headerName: "צירוף אחים",
        field: "nvImportToAttach",
        width: 160,
        filter: "agTextColumnFilter",
        sortable: true
      },
      {
        headerName: "משפחת",
        field: "nvFamilyName",
        width: 100,
        filter: "agTextColumnFilter",
        sortable: true
      },
      {
        headerName: "טלפון",
        field: "nvPhone",
        width: 100,
        filter: "agTextColumnFilter",
        sortable: true
      },
      {
        headerName: "כתובת",
        field: "nvAddress",
        width: 100,
        filter: "agTextColumnFilter",
        sortable: true
      },
      {
        headerName: "בית",
        field: "nvNumHouse",
        width: 80,
        filter: "agTextColumnFilter",
        sortable: true
      },
      {
        headerName: "שכונה",
        field: "nvNeighborhood",
        width: 100,
        filter: "agTextColumnFilter",
        sortable: true
      },
      {
        headerName: "עיר",
        field: "nvCityName",
        width: 90,
        filter: "agTextColumnFilter",
        sortable: true
      },
 /*       {
        headerName: "מיקוד",
        field: "nvZipCode",
        width: 90,
        filter: "agTextColumnFilter"
      }, */
      {
        headerName: "אב",
        field: "nvFirstNameF",
        width: 100,
        filter: "agTextColumnFilter",
        sortable: true
      },
      {
        headerName: "מז אב",
        field: "nvIdentityF",
        width: 100,
        filter: "agTextColumnFilter",
        sortable: true
      },
      {
        headerName: "נייד אב",
        field: "nvMobileF",
        width: 100,
        filter: "agTextColumnFilter",
        sortable: true
      },
      {
        headerName: "אם",
        field: "nvFirstNameM",
        width: 100,
        filter: "agTextColumnFilter",
        sortable: true
      },
      {
        headerName: "מז אם",
        field: "nvIdentityM",
        width: 100,
        filter: "agTextColumnFilter",
        sortable: true
      },
      {
        headerName: "נייד אם",
        field: "nvMobileM",
        width: 100,
        filter: "agTextColumnFilter",
        sortable: true
      },
      {
        headerName: "תלמיד",
        field: "nvFirstNameC",
        width: 100,
        filter: "agTextColumnFilter",
        sortable: true
      }
 /*       {
        headerName: "מז תלמיד",
        field: "nvIdentityC",
        width: 100,
        filter: "agTextColumnFilter"
      } */
    ];
    this.GetDataToReview();
  }

  GetDataToReview() {
    this.serv.GetDataToReview().then(res => {
      this.isVisible = true;
      this.rowData1 = res;
    });
  }

  onGridReady1(params) {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
    params.api.sizeColumnsToFit();
  }

  AttachImportData(params) {
    if (params.iDataToImportID) {
      this.iDataToImportID = params.iDataToImportID;
      this.ChildToImport = params;

      this.lFamilies = new Array<CodeTable>();
      this.serv.GetFamilyList().then(res => {
        for (let i = 0; i < res.length; i++) {
          this.lFamilies.push({
            length: null,
            id: res[i].iFamilyId,
            text: res[i].FamilyDetails
          });
        }
      });
      
      this.lImports = new Array<CodeTable>();
      this.serv.GetImportList().then(res => {
        for (let i = 0; i < res.length; i++) {
          this.lImports.push({
            length: null,
            id: res[i].iDataToImportID,
            text: res[i].nvImportToAttach
          });
        }
      });

      this.isVisibleAttachImportData = true;
    }
  }

  UpdChildToImport(){
    this.serv.UpdChild(this.ChildToImport).then(res => {
      if (res) {
        this.isVisibleAttachImportData = false;
      }
    });
    this.GetDataToReview();
  }

  DeleteImportData(){
    this.serv.DeleteImportData().then(res => {
      
    });
    this.GetDataToReview()
  }

  FinishImport(){
    this.serv
    .FinishImporting(this.iYearId)
    .then(res => {
      if (res > 0) {
        alert("בוצע בהצלחה");
        this.getFamilys();
      } else alert("היתה בעיה ביבוא הנתונים");
    });
    this.isVisible = false;
  }
  
}
