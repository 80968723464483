import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { FormGroup, FormControl } from '@angular/forms';
import { paymentDetails } from '../../../Models/paymentDetails';
import { ServService } from '../../serv.service';
import { DetailsPaymentComponent } from '../../../Components/details-payment/details-payment.component';
import { dailog } from '../dailog/dailog';
import { MatDialog } from '../../../../node_modules/@angular/material/dialog';
import { from } from 'rxjs';
import * as moment from "moment"

@Component({
  selector: 'app-form-payment-details',
  templateUrl: './form-payment-details.component.html',
  styleUrls: ['./form-payment-details.component.css']
})
export class FormPaymentDetailsComponent implements OnInit {

  @Input() pDetails;
  @Output() close = new EventEmitter();
  flag: boolean = false;
  iPaymentMethodTypeTemp: string = "";
  backgroundColor: string;
  form: FormGroup;
  visibleCreditCard: boolean;
  visibleDirectDebit: boolean;
  isVisibleDeletePopup: boolean = false;
  iDebitDay: number;
  iCanceledCount: number;
  // newPDetails:paymentDetails=new paymentDetails()
  isVisible: boolean = false;
  detailsPayment: DetailsPaymentComponent;
  isDisable: Boolean;
  expDate: string = null;
  selectDisabled = true;
  constructor(public service: ServService, private dialog: MatDialog) {
    this.backgroundColor = this.service.backgroundColor[0];
    this.isDisable = true;
  }

  ngOnInit() {
    this.form = new FormGroup({
      nvPaymentMethodType: new FormControl(),
      iDebitDay: new FormControl(),
      ipriorityType: new FormControl(),
      dPausedToDate: new FormControl(),
      nFixedAmount: new FormControl(),
      nMaxAmount: new FormControl(),
      nvEndDate: new FormControl(),
      nvBankName: new FormControl(),
      iBranchNumber: new FormControl(),
      iaccountNumber: new FormControl(),
      nvCardLastFourDigits: new FormControl(),
      nvExpirationDate: new FormControl(),
      CVV: new FormControl(),
      nvAssociationName: new FormControl(),
      nvStatusType: new FormControl(),
      bExternalCharge: new FormControl(),
      nvAccountName: new FormControl(),
      nvAccountIdentity: new FormControl(),
      iRateType: new FormControl(),
      nvCardName: new FormControl(),
      pDetailsDebitDay: new FormControl(),
    })

    // if (this.pDetails.iPaymentMethodType == 13)
    //   this.iPaymentMethodTypeTemp = "אשראי"
    // if (this.pDetails.iPaymentMethodType == 14)
    //   this.iPaymentMethodTypeTemp = "הוק"

  }

  edit(currentPayment) {
    this.service.nvExpDate = currentPayment.nvExpirationDate;
    this.service.newPDetails = currentPayment;
    this.service.isFormPaymentVisible = true;
    this.isVisible = true;
    this.service.isReadOnly = true;
    if (currentPayment.iPaymentMethodType == 13) {
      this.service.visibleCreditCard = true;
      this.service.visibleDirectDebit = false;
      this.service.newPDetails.nvCard = this.service.newPDetails.nvCardLastFourDigits;
    }
    else {
      this.service.visibleDirectDebit = true;
      this.service.visibleCreditCard = false;
    }

    this.isVisible = true;
  }

  getPaymentDetails() {
    this.service.GetPaymentDetails(this.service.user.iUserId, this.service.currentiFamilyId,false)
      .then(res => {
        for (let i = 0; i < res.lPaymentDetails.length; i++) {
         //  if (res.lPaymentDetails[i].dEndDate)
           //  res.lPaymentDetails[i].dEndDate = moment(res.lPaymentDetails[i].dEndDate).format("DD/MM/YYYY");
          if (res.lPaymentDetails[i].dExpirationDate)
            res.lPaymentDetails[i].dExpirationDate = moment(res.lPaymentDetails[i].dExpirationDate).format("DD/MM/YYYY");

        }
        this.iDebitDay = res.iDebitDay;
        this.pDetails = res.lPaymentDetails;
        this.iCanceledCount = res.iCanceledCount;
      })
  }

  delete() {
    this.isVisibleDeletePopup = true;
  }

  deletePayment(currentPayment) {
    this.service.DeletePaymentDetails(currentPayment.iPaymentMethodId)
      .then(res => {
        this.dialog.open(dailog, {
          hasBackdrop: true,
          height: "200px",
          width: "300px",
          data: "אמצעי התשלום נמחק בהצלחה",
        });
        this.getPaymentDetails();
        //location.reload();
      });
  }


  onSelectDebitDay() {
    // this.service.
    // this.service.UpdDebitDay(this.iDebitDay, this.service.currentiFamilyId).then(res => {
      // if (res == "success") {
      //   this.dailog.open(dailog, {
      //     hasBackdrop: true,
      //     height: "200px",
      //     width: "300px",
      //     data: "נשמר בהצלחה!"
      //   });
      // } else if (res == "error") {
      //   this.dailog.open(dailog, {
      //     hasBackdrop: true,
      //     height: "200px",
      //     width: "300px",
      //     data: "ארעה שגיאה בלתי צפויה"
      //   });
      // } else {
      //   this.dailog.open(dailog, {
      //     hasBackdrop: true,
      //     height: "200px",
      //     width: "300px",
      //     data: res
      //   });
      // }
    // });
  }


}